import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { selectLoadZendesk } from '@/store/selectors/commonSelectors';

function getWindow(): any {
  return window;
}

export const openZendesk = (): void => {
  const w = getWindow();
  if (w && w.zE) {
    w.zE('messenger', 'show');
    w.zE('messenger', 'open');
  }
};

export function Zendesk(): JSX.Element | null {
  const loadZendesk = useSelector(selectLoadZendesk);
  const [zendeskScriptLoaded, setZendeskScriptLoaded] = useState(false);
  const [zendeskShown, setZendeskShown] = useState(false);

  useEffect(() => {
    if (!loadZendesk) {
      return;
    }
    let script: HTMLScriptElement | null = null;
    if (window && document && !zendeskScriptLoaded) {
      script = document.createElement('script');
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_KEY}`;
      script.id = 'ze-snippet';
      script.async = true;
      script.onload = () => {
        setZendeskScriptLoaded(true);
      };
      document.head.appendChild(script);
    }

    return () => {
      if (script) {
        script.remove();
      }
    };
  }, [loadZendesk]);

  useEffect(() => {
    const w = getWindow();
    if (w && w.zE) {
      w.zE('messenger:set', 'locale', 'ro');
      w.zE('messenger', 'hide');
      w.zE('messenger:set', 'conversationFields', [
        { id: '25891516613778', value: window.location.href },
      ]);
      w.zE('messenger:on', 'open', function () {
        setZendeskShown(true);
      });

      w.zE('messenger:on', 'close', function () {
        setZendeskShown(false);
      });
    }
  }, [zendeskScriptLoaded]);

  return (
    <>
      <Helmet>
        {!zendeskShown && (
          <style type="text/css">
            {`iframe#launcher {
          display: none;
        }`}
          </style>
        )}
      </Helmet>
    </>
  );
}
