import React, { useCallback, useRef } from 'react';

import { InformationCircleIcon, LockClosedIcon } from '@heroicons/react/24/solid';
import { BffErrorCode, BffValidateEmailPhoneResponse } from '@lucky7ventures/bff-types';
import { FormikHelpers } from 'formik';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Button from '@/components/button/Button';
import Form from '@/components/form/Form';
import DateOfBirthField from '@/components/form/date-of-birth/DateOfBirthField';
import EmailField from '@/components/form/email/EmailField';
import PasswordField from '@/components/form/password/PasswordField';
import PhoneNumberField from '@/components/form/phone-number/PhoneNumberField';
import TextField from '@/components/form/text/TextField';
import Tooltip from '@/components/tooltip/Tooltip';
import { openZendesk } from '@/features/zendesk/Zendesk';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { ModalType } from '@/models/enums/modal-type.enum';
import BffApiService from '@/shared/bff-api-service';
import { openModal } from '@/store/actions/modalActions';
import {
  BasicRegistrationInfo,
  useBasicInfoData,
  useRegistrationActions,
} from '@/store/registrationStore';
import { cdnImage } from '@/utils/image-utils';
import { extractBirthdateFromCNP } from '@/utils/registration-utils';

const currentYear = new Date().getFullYear();
const minYear = currentYear - 100;

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Necesar').email('Format de e-mail greșit').trim(),
  password: Yup.string()
    .trim()
    .min(6, 'Parola trebuie să aibă cel puțin 6 caractere')
    .test(
      'no-whitespace',
      'Vă rugăm să furnizați parola fără spații albe',
      value => !(value && /\s/.test(value)),
    )
    .matches(/^(?=.*\d)(?=.*[a-z]).{6,25}$/, 'Utilizați o combinație de litere și cifre ')
    .required('Necesar'),
  cnp: Yup.string()
    .required('Necesar')
    .matches(
      /\b[1-9][0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12][0-9]|3[01])(?:0[1-9]|[1-3][0-9]|4[0-6]|51|52)[0-9]{4}\b/,
      'Vă rugăm să ne furnizați un CNP valid',
    ),
  birthDate: Yup.object()
    .shape({
      day: Yup.string()
        .required('Necesar')
        .test('valid-day', 'Vă rugăm să introduceți o zi valabilă', function (value) {
          const month = this.parent.month;
          const year = this.parent.year;
          if (!value || !month || !year) {
            return true; // Skip validation if any field is missing
          }
          const numericDay = parseInt(value);
          const daysInMonth = new Date(parseInt(year), parseInt(month), 0).getDate();
          return numericDay <= daysInMonth && numericDay > 0;
        }),
      month: Yup.string()
        .required('Necesar')
        .matches(/^(0?[1-9]|1[0-2])$/, 'Vă rugăm să introduceți o lună valabilă'),
      year: Yup.string()
        .required('Necesar')
        .matches(/^(19|20)\d\d$/, 'Vă rugăm să introduceți un an valabil')
        .test('is-valid-year', 'Interval de ani nevalid', value => {
          const numericYear = parseInt(value ?? '', 10);
          return numericYear >= minYear && numericYear <= currentYear;
        }),
    })
    .test('is-18-plus', 'Trebuie să ai 18 ani sau mai mult pentru a juca', function (dob) {
      if ((dob && !dob?.day) || !dob?.month || !dob?.year) {
        return false;
      }
      const { day, month, year } = dob;
      const birthDate = DateTime.local(parseInt(year), parseInt(month), parseInt(day));
      const age = DateTime.now().diff(birthDate, 'years').years;
      return age >= 18;
    }),
  phone: Yup.object().shape({
    wholeNumber: Yup.string()
      .required('Necesar')
      .trim()
      .min(5, 'Vă rugăm să introduceți un număr de telefon valid'),
    number: Yup.string()
      .min(5, 'Vă rugăm să introduceți un număr de telefon valid')
      .matches(/^[\d\s\-/]+$/, 'Vă rugăm să introduceți un număr de telefon valid')
      .trim(),
  }),
});

const BasicInfoForm = ({ stepForward }: { stepForward: () => void }) => {
  const {
    request: validateEmailPhoneRequest,
    data: validateEmailPhoneData,
    error: bffApiError,
  } = useBffApiRequest<BffValidateEmailPhoneResponse>();
  const { email, password, birthDate, phone, cnp } = useBasicInfoData();
  const { updateField, updateFields } = useRegistrationActions();
  const prevEmailRef = useRef('');
  const prevPhoneRef = useRef({
    number: '',
    country: '',
    prefix: '',
    wholeNumber: '',
  });

  const dispatch = useDispatch();

  const initialValues: BasicRegistrationInfo = {
    email,
    password,
    cnp,
    birthDate: {
      day: birthDate.day,
      month: birthDate.month,
      year: birthDate.year,
    },
    phone: {
      number: phone.number,
      country: phone.country,
      prefix: phone.prefix,
      wholeNumber: phone.wholeNumber,
    },
  };

  const handleOpenLogin = useCallback(() => {
    triggerGTag(GTagEvents.reg_login);
    dispatch(openModal(ModalType.Login));
  }, []);

  const setEmailPhoneFieldErrors = useCallback(
    (
      email: BffValidateEmailPhoneResponse['emailError'],
      phone: BffValidateEmailPhoneResponse['phoneError'],
      setFieldError: (field: string, message: string | undefined) => void,
    ) => {
      if (email) {
        switch (email) {
          case BffErrorCode.GIG_EMAIL_ALREADY_EXISTS:
            setFieldError('email', 'E-mailul există deja');
            triggerGTag(GTagEvents.reg_failed_email_already_exist);
            break;
          default:
            setFieldError('email', 'Vă rugăm să furnizați un e-mail valid');
        }
      }

      if (phone) {
        switch (phone) {
          case BffErrorCode.GIG_MOBILE_AND_PREFIX_COMBINATION_ALREADY_REGISTERED:
            triggerGTag(GTagEvents.reg_mobile_exists_error);
            setFieldError('phone.wholeNumber', 'Numărul de telefon mobil există deja');
            break;
          case BffErrorCode.GIG_MOBILE_AND_PREFIX_INVALID:
            triggerGTag(GTagEvents.reg_mobile_invalid_error);
            setFieldError('phone.wholeNumber', 'Telefonul mobil sau prefixul nu sunt valide');
            break;
          default:
            setFieldError('phone.wholeNumber', 'Vă rugăm să introduceți un număr de telefon valid');
        }
      }
    },
    [],
  );

  const handleOnSubmit = useCallback(
    (values: BasicRegistrationInfo, { setFieldError }: FormikHelpers<BasicRegistrationInfo>) => {
      updateFields(values);
      triggerGTag(GTagEvents.reg_step1_continue_cta_click);

      const successCallback = ({ emailError, phoneError }: BffValidateEmailPhoneResponse) => {
        if (!emailError && !phoneError) {
          triggerGTag(GTagEvents.reg_step1_validate_success);
          stepForward();
        }

        setEmailPhoneFieldErrors(emailError, phoneError, setFieldError);
      };

      const errorCallback = () => {
        triggerGTag(GTagEvents.reg_step1_validate_error);
        stepForward();
      };

      // We want to trigger the validation request only if there
      // was a change in the email or phone field values between submits
      if (
        prevEmailRef.current !== values.email ||
        prevPhoneRef.current.wholeNumber !== values.phone.wholeNumber
      ) {
        validateEmailPhoneRequest({
          apiMethod: BffApiService.validateEmailPhone,
          payload: {
            email: values.email,
            prefix: values.phone.prefix,
            phone: values.phone.number,
          },
          successCallback,
          errorCallback,
        });
      } else {
        if (
          !validateEmailPhoneData?.emailError &&
          !validateEmailPhoneData?.phoneError &&
          !bffApiError
        ) {
          stepForward();
        } else {
          // We need to manually set up the errors again, since Formik clears
          // errors state on submit
          setEmailPhoneFieldErrors(
            validateEmailPhoneData?.emailError,
            validateEmailPhoneData?.phoneError,
            setFieldError,
          );
        }
      }
      prevEmailRef.current = values.email;
      prevPhoneRef.current = values.phone;
    },
    [stepForward, validateEmailPhoneData],
  );

  return (
    <div className="w-full h-full flex flex-col gap-7">
      <span className="text-gray-700 text-sm text-center">
        Să-ți faci un cont îți ia mai puțin de
        <span className="text-brand-gold font-bold"> 2 minute</span>
      </span>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        className="flex flex-col justify-between gap-8"
      >
        {() => (
          <>
            <div className="flex flex-col gap-6">
              <EmailField
                data-cy="registration-basic-info-email"
                label="E-mail"
                name="email"
                labelClassName="text-gray-700"
                onBlur={({ target: { value } }) => updateField('email', value)}
                onValidBlur={() => triggerGTag(GTagEvents.reg_email_valid)}
                onValidationError={error => triggerGTag(GTagEvents.reg_email_error, { error })}
                onFocus={() => triggerGTag(GTagEvents.reg_email_click)}
                autoComplete="off"
                className="focus-within:border-brand-gold border-[1px] border-brand-gold/40 text-gray-700"
              />
              <PasswordField
                data-cy="registration-basic-info-password"
                label="Parolă"
                name="password"
                labelClassName="text-gray-700"
                iconClassName="text-gray-700"
                onBlur={({ target: { value } }) => updateField('password', value)}
                onValidationError={error => triggerGTag(GTagEvents.reg_password_error, { error })}
                onFocus={() => triggerGTag(GTagEvents.reg_password_click)}
                autoComplete="new-password"
                placeholder="Parolă de minim 6 caractere"
                className="focus-within:border-brand-gold border-[1px] border-brand-gold/40 text-gray-700"
              />
              <PhoneNumberField
                data-cy="registration-basic-info-phone"
                label="Telefon mobil"
                name="phone"
                labelClassName="text-gray-700"
                onBlur={({ target: { value } }) => {
                  const [prefix, ...rest] = value.split(' ');
                  updateField('phone', {
                    prefix,
                    number: rest.join('').replace(/[^0-9]/g, ''),
                    wholeNumber: value,
                    country: 'RO', // react phone input lib will get country from the prefix
                  });
                }}
                onFocus={() => triggerGTag(GTagEvents.reg_mobile_click)}
                onValidationError={error => triggerGTag(GTagEvents.reg_mobile_error, { error })}
                className="focus-within:border-brand-gold border-[1px] border-brand-gold/40 text-gray-700/80
                [&_input]:placeholder:!text-white/0"
              />
              <TextField
                data-cy="registration-basic-info-cnp"
                label="Cod numeric personal"
                labelClassName="text-gray-700"
                name="cnp"
                inputMode="numeric"
                endIcon={
                  <Tooltip
                    className="max-w-[260px] xxs:max-w-[310px] xs:max-w-[380px]"
                    position="top-left"
                    text={
                      <div>
                        Îți poți găsi CNP-ul verificându-ți cartea de identitate personală
                        <img
                          alt="CNP-ID"
                          src={cdnImage('cnp-example.jpg', {
                            width: '400px',
                            height: 'auto',
                            fit: 'contain',
                          })}
                        />
                      </div>
                    }
                  >
                    <InformationCircleIcon className="w-5 h-5 text-gray-700" />
                  </Tooltip>
                }
                onBlur={({ target: { value } }) => {
                  updateField('cnp', value);
                  const dob = extractBirthdateFromCNP(value);
                  if (dob !== null) updateField('birthDate', dob);
                  if (dob === null) {
                    updateField('birthDate', { day: '', month: '', year: '' });
                  }
                }}
                onFocus={() => triggerGTag(GTagEvents.reg_cnp_click)}
                onValidationError={error => triggerGTag(GTagEvents.reg_cnp_error, { error })}
                onChange={({ target: { value } }) => {
                  if (value.length === 13) {
                    updateField('cnp', value);
                    const dob = extractBirthdateFromCNP(value);
                    if (dob !== null) updateField('birthDate', dob);
                  } else {
                    updateField('birthDate', { day: '', month: '', year: '' });
                  }
                }}
                maxLength={13}
                placeholder="X YYMMDD XX XXX X"
                className="focus-within:border-brand-gold border-[1px] border-brand-gold/40 text-gray-700"
              />
              <DateOfBirthField
                disabled
                data-cy="registration-basic-info-dob"
                label="Data naşterii"
                name="birthDate"
                labelClassName="text-gray-700"
                prefillValue={birthDate}
                onFocus={() => triggerGTag(GTagEvents.reg_dob_click)}
                onValidationError={error => triggerGTag(GTagEvents.reg_dob_error, { error })}
                endIcon={
                  <Tooltip
                    position="left"
                    text="Trebuie să ne asigurăm că aveți 18+. Nu puteți modifica aceste date, acestea sunt extrase din CNP."
                    className="max-w-[240px] sm:max-w-[400px]"
                  >
                    <LockClosedIcon className="w-5 h-5 text-gray-700" />
                  </Tooltip>
                }
                className="focus-within:border-brand-gold border-[1px] border-brand-gold/40 text-gray-700"
              />
            </div>
            {bffApiError && (
              <p
                onClick={() => {
                  triggerGTag(GTagEvents.reg_error_step1_help_click);
                  openZendesk();
                }}
                className="cursor-pointer m-0 w-full bg-red-300 rounded-xl px-4 py-3 text-red-700 text-sm"
              >
                Ceva nu a mers bine. Vă rugăm să încercați din nou mai târziu sau să contactați
                serviciul nostru de asistență. [{bffApiError.code}]
              </p>
            )}
            <div className="flex flex-col items-center gap-4 mt-4 sm:mt-6 mb-3">
              <Button data-cy="registration-basic-info-submit" type="submit">
                Continua
              </Button>
              <div className="text-gray-700/70 text-[15px] text-center">
                <span>
                  Ai deja un cont?{' '}
                  <span
                    onClick={handleOpenLogin}
                    className="text-gray-700 font-bold underline cursor-pointer"
                  >
                    Intră în cont
                  </span>
                </span>
              </div>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default BasicInfoForm;
