import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { useCookie, useLocation, useUpdateEffect } from 'react-use';

import GlobalStyles from '../styles/GlobalStyles';
import CookieBanner from '@/components/cookie-banner/CookieBanner';
import Footer from '@/components/footer/Footer';
import Header from '@/components/header/Header';
import MobileNav from '@/components/navigation/MobileNav';
import ScrollToTop from '@/components/navigation/ScrollToTop';
import CashierDrawerContainer from '@/features/cashier/CashierDrawerContainer';
import DrawerContainer from '@/features/drawer/DrawerContainer';
import ModalContainer from '@/features/modals/ModalContainer';
import AppRoutes from '@/features/router/AppRoutes';
import ToastContainer from '@/features/toasts/ToastContainer';
import { Zendesk } from '@/features/zendesk/Zendesk';
import useConsents from '@/hooks/useConsents';
import useCookiesSync from '@/hooks/useCookiesSync';
import useFreeSpinsToast from '@/hooks/useFreeSpinsToast';
import useKycToast from '@/hooks/useKycToast';
import useLigaReset from '@/hooks/useLigaReset';
import useLigaToast from '@/hooks/useLigaToast';
import useParamsDrawer from '@/hooks/useParamsDrawer';
import usePingLogin from '@/hooks/usePingLogin';
import useTagManagerInit from '@/hooks/useTagManagerInit';
import { ModalType } from '@/models/enums/modal-type.enum';
import { checkIfLoggedIn } from '@/store/actions/authActions';
import { getGames, getGamesJackpots, getLastPlayedGames } from '@/store/actions/gamesActions';
import { openModal } from '@/store/actions/modalActions';
import { useAuthenticationInfoData, useRegistrationStore } from '@/store/registrationStore';
import { selectAuthIsAuthenticated, selectAuthToken } from '@/store/selectors/authSelectors';
import { isEmpty } from '@/utils/helpers';

function App() {
  const dispatch = useDispatch();
  const [cookieToken] = useCookie('mjp_token');
  const token = useSelector(selectAuthToken);
  const isAuthenticated = useSelector(selectAuthIsAuthenticated);
  const { search, pathname } = useLocation();
  const { token: registrationToken } = useAuthenticationInfoData();
  const { reset } = useRegistrationStore();

  usePingLogin();
  useParamsDrawer();
  useKycToast();
  useLigaToast();
  useFreeSpinsToast();
  useCookiesSync();
  useConsents();
  useTagManagerInit();
  useLigaReset();

  useEffect(() => {
    if (!isEmpty(search)) {
      const params = new URLSearchParams(search);
      const modal = params.get('m');
      if (modal === 'termeni') {
        dispatch(openModal(ModalType.GeneralBonusTerms));
      }
    }
  }, []);

  useEffect(
    () => !pathname.includes('inregistrare') && registrationToken && reset(),
    [registrationToken, pathname],
  );

  useEffect(() => {
    if (cookieToken) {
      dispatch(checkIfLoggedIn(cookieToken));
    }
    dispatch(getGames());
    dispatch(getGamesJackpots());
  }, []);

  // User logged in or out
  useUpdateEffect(() => {
    let interval;

    if (isAuthenticated && token) {
      window.scrollTo(0, 0);
      dispatch(getLastPlayedGames());
    }

    return () => {
      clearInterval(interval);
    };
  }, [isAuthenticated]);

  return (
    <>
      <Helmet>
        <title>
          MagicJackpot - Cazinou Online | Ai 600 Rotiri Gratuite și până la 2400 Lei BONUS
        </title>
        <meta
          name="description"
          content="Joacă cele mai noi și populare jocuri la MagicJackpot. Înregistrează-te azi pentru a revendica bonusul de bun venit și alege jocurile dintre secțiunile Sloturi, Jackpot, Ruletă și Blackjack."
        />
        {process.env.REACT_APP_SENTRY_RELEASE && (
          <meta name="magicjackpot_version" content={process.env.REACT_APP_SENTRY_RELEASE} />
        )}
        <script type="text/javascript" src="/applepay.js"></script>
      </Helmet>
      <Header />
      <AppRoutes />
      <Footer />
      <Zendesk />
      <MobileNav />
      <ModalContainer />
      {!pathname.includes('inregistrare') && <ToastContainer />}
      <DrawerContainer />
      <CashierDrawerContainer />
      <ScrollToTop />
      <GlobalStyles />
      <CookieBanner />
    </>
  );
}

export default App;
