import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyles = createGlobalStyle`
   body, html {
      font-size: 16px;
      font-family: 'Work Sans', sans-serif;
      color: ${props => props.theme.fontBlack};

      &.drawer-open, &.ReactModal__Body--open {
         overflow: hidden;
      }
   }

   body {
      overscroll-behavior-y: none;

      &.header-background {
         #header {
            background: ${props => props.theme.redGradient};
         }
      }

      &.single-game {
         overflow: hidden;

         @media only screen and (max-device-width: 900px) and (orientation: landscape) {
            height: 830px;
            overflow: inherit;
         }

         #footer, #mobile-nav, #header {
            display: none;
         }
      }

      &.settings {
         #footer {
            display: none;
         }
      }

      &.game-rules {
         #footer, #header, #mobile-nav {
            display: none;
         }
      }
   }

   * {
      box-sizing: border-box;

      &:focus {
         outline: none;
      }
   }

   h1, .h1, h2, .h2, h3, .h3 {
      color: ${props => props.theme.black};
      font-weight: 700;
   }

   h1, .h1 {
      font-size: 32px;
      margin-bottom: 32px;
   }

   h2, .h2 {
      font-size: 24px;
      line-height: 1.2;
   }

   .flex-wrapper {
      width: 100%;
      max-width: 1400px;
      margin: 0 auto;
      padding: 0 16px;
      position: relative;

      .row {
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         width: 100%;
         padding: -16px;

         .column {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin: 16px;
         }
      }
   }

   .flex-center {
      justify-content: center;
      align-items: center;
   }

   a, button {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      font-size: 1rem;
      font-family: 'Work Sans', sans-serif;
      border: none;
      background: none;
      padding: 0;
      margin: 0;
   }

   .hejsan {
      margin-top: 32px;
   }

   p {
      line-height: 1.6;
      margin-bottom: 1rem;

      &.small {
         font-size: 14px;

         a {
            font-weight: 700;
         }
      }
   }

   input {
		-webkit-appearance: none;
   }

   ul.styled {
      margin-top: 16px;
      margin-bottom: 16px;
      list-style: none;
      padding-left: 24px;

      li {
         margin-bottom: 16px;
         line-height: 1.6;
         position: relative;

         &:before {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 0 6px 10px;
            border-color: transparent transparent transparent ${props => props.theme.yellow};
            left: -24px;
            top: 5px;
            position: absolute;
         }

         > p, button, a {
            display: inline;
         }

         p:last-child {
            margin-bottom: 0;
         }

         button, a {
            color: ${props => props.theme.green};
            margin-left: 8px;
         }
      }
   }

   strong {
      font-weight: 700;
   }

   em {
      font-style: italic;
   }

   .spacer {
      display: block;
   }

   .capitalize {
      text-transform: capitalize;
   }

   .hide-on-small {
      display: none;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
         display: block;
      }
   }

   .show-on-small {
      display: block;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
         display: none;
      }
   }

   .absolute-center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-bottom: 0;
   }

   .slide-left-appear,
	.slide-right-appear,
	.fade-appear,
	.fade-enter {
		opacity: 0;
	}

	.slide-left-appear-active,
	.slide-right-appear-active,
	.fade-appear-active,
	.fade-enter-active {
		opacity: 1;
		transition: opacity 400ms;
	}

	.fade-exit {
		opacity: 1;
	}

	.fade-exit-active {
		opacity: 0;
		transition: opacity 400ms;
	}

	.slide-left-exit {
		transform: translateX(0);
		opacity: 1;
	}

	.slide-left-exit-active {
		transform: translateX(-100%);
		opacity: 0;
		transition: transform 400ms, opacity 400ms;
	}

	.slide-left-enter {
		transform: translateX(100%);
		opacity: 0;
	}

	.slide-left-enter-active {
		transform: translateX(0);
		opacity: 1;
		transition: transform 400ms, opacity 400ms;
	}

	.slide-right-exit {
		transform: translateX(0);
		opacity: 1;
	}

	.slide-right-exit-active {
		transform: translateX(100%);
		opacity: 0;
		transition: transform 400ms, opacity 400ms;
	}

	.slide-right-enter {
		transform: translateX(-100%);
		opacity: 0;
	}

	.slide-right-enter-active {
		transform: translateX(0);
		opacity: 1;
		transition: transform 400ms, opacity 400ms;
	}


	.slide-down-enter {
		height: 0;
		opacity: 0;
	}

	.slide-down-enter-active {
		height: 461px;
		opacity: 1;
		transition: height 200ms, opacity 200ms;
	}

	.slide-down-exit {
		height: 461px;
		opacity: 1;
	}

	.slide-down-exit-active {
		height: 0;
		opacity: 0;
		transition: height 200ms, opacity 200ms;
	}

	.fade-enter {
		opacity: 0;
	}

	.fade-enter-active {
		opacity: 1;
		transition: opacity 400ms;
	}

  .paginated-table {
    box-shadow: ${props => props.theme.boxShadow};

    thead {
      th {
        background: ${props => props.theme.black};
        color: white;
      }
    }

    tr {
      &:nth-child(odd) {
        background: ${props => props.theme.offWhite};
      }
    }

    tfoot {
      border-top: 1px solid rgba(0,0,0,0.02);

      tr {
        td {
          border-top: 1px solid ${props => props.theme.offWhite};
          background: white;
        }
      }
    }

    .paginated-table-controls {
      button {
        &.active {
          background: ${props => props.theme.lightMint};
          border-color: ${props => props.theme.mint};
        }
      }
    }
  }
`;

export default GlobalStyles;
