import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import Button from '@/components/button/Button';
import PageHeader from '@/components/header/PageHeader';
import { openZendesk } from '@/features/zendesk/Zendesk';
import Faq from '@/pages/help/Faq';
import { loadZendesk } from '@/store/actions/commonActions';

const StyledHelp = styled.div`
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem 4rem;
  }
`;

function Help(): JSX.Element {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadZendesk());
  }, []);

  return (
    <StyledHelp>
      <PageHeader>
        <h1>Ai nevoie de ajutor?</h1>
        <p>Nicio problemă! Consultă secțiunea Întrebări și Răspunsuri sau contactează-ne.</p>
        <Button color="ghost" size="small" onClick={() => openZendesk()}>
          Contactează-ne
        </Button>
      </PageHeader>
      <div className="container">
        <Faq />
      </div>
    </StyledHelp>
  );
}

export default Help;
