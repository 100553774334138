import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { BffErrorCode, BffRegisterResponse } from '@lucky7ventures/bff-types';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import Button from '@/components/button/Button';
import Form from '@/components/form/Form';
import SwitchField from '@/components/form/switch/SwitchField';
import { openZendesk } from '@/features/zendesk/Zendesk';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import { GTagEvents, triggerGTag, triggerNetReferer } from '@/lib/gTagManager';
import Divider from '@/pages/registration/Divider';
import { BffApiError, FrontendErrorCode } from '@/shared/api-error-handler';
import BffApiService from '@/shared/bff-api-service';
import { loginSuccess } from '@/store/actions/authActions';
import { loadZendesk } from '@/store/actions/commonActions';
import { Consents, useRegistrationActions, useRegistrationData } from '@/store/registrationStore';
import { selectBtag } from '@/store/selectors/cookiesSelectors';
import CookiesManagerUtils from '@/utils/cookies-manager-utils';

const validationSchema = Yup.object().shape({
  tcConsent: Yup.bool()
    .required('Necesar')
    .test('tcConsent', 'Vă rugăm să acceptați să continuați', value => !!value),
  marketingConsent: Yup.bool().required('Necesar'),
  pepHioConsent: Yup.bool()
    .required('Necesar')
    .test('pepHioConsent', 'Nu poți juca dacă ești PEP/HIO', value => !!value),
  privacyAndCookiePolicyConsent: Yup.bool()
    .required('Necesar')
    .test('privacyAndCookiePolicyConsent', 'Vă rugăm să acceptați să continuați', value => !!value),
});

const ConsentsForm = ({
  stepBack,
  setShowError,
}: {
  stepBack: () => void;
  setShowError: (errorCode: BffErrorCode | undefined) => void;
}) => {
  const { tcConsent, marketingConsent, privacyAndCookiePolicyConsent, pepHioConsent, ...rest } =
    useRegistrationData();
  const { updateField, reset: resetRegistrationStore } = useRegistrationActions();
  const { request: registerRequest, loading } = useBffApiRequest<BffRegisterResponse>();
  const [errorMessage, setErrorMessage] = useState('');
  const btag = useSelector(selectBtag);

  const dispatch = useDispatch();
  const { updateFields } = useRegistrationActions();

  useEffect(() => {
    dispatch(loadZendesk());
  }, []);

  const data = useMemo(
    () => [
      {
        name: 'marketingConsent',
        label: 'Marketing',
        labelClassName: 'text-gray-700 font-bold',
        onChange: (checked: boolean) => {
          updateField('marketingConsent', checked);
          triggerGTag(GTagEvents.reg_marketing_click);
        },
        'data-cy': 'registration-consents-info-marketing',
      },
      {
        name: 'tcConsent',
        label: (
          <a
            href={`${window.location.origin}/termeni-si-conditii`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-700 font-bold underline cursor-pointer m-0 text-sm sm:text-base"
          >
            Termeni și condiții
          </a>
        ),
        onChange: (checked: boolean) => {
          triggerGTag(GTagEvents.reg_terms_click);
          updateField('tcConsent', checked);
        },
        'data-cy': 'registration-consents-info-terms',
        validationErrorEvent: GTagEvents.reg_terms_error,
      },
      {
        name: 'privacyAndCookiePolicyConsent',
        label: (
          <p className="m-0 text-sm sm:text-base">
            <a
              href={`${window.location.origin}/declaratie-de-confidentialitate`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 font-bold underline cursor-pointer m-0 text-sm sm:text-base"
            >
              Politica de confidențialitate
            </a>{' '}
            și{' '}
            <a
              href={`${window.location.origin}/politica-de-utilizare-a-cookie-urilor`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 font-bold underline cursor-pointer m-0 text-sm sm:text-base"
            >
              Cookie
            </a>
          </p>
        ),
        onChange: (checked: boolean) => {
          triggerGTag(GTagEvents.reg_privacy_click);
          updateField('privacyAndCookiePolicyConsent', checked);
        },
        'data-cy': 'registration-consents-info-privacy-cookie',
        validationErrorEvent: GTagEvents.reg_privacy_error,
      },
      {
        name: 'pepHioConsent',
        label: 'Nu sunt o persoană expusă politic sau șef de organizație internațională',
        labelClassName: 'text-gray-700 font-bold',
        onChange: (checked: boolean) => {
          triggerGTag(GTagEvents.reg_pep_hio_consent_click);
          updateField('pepHioConsent', checked);
        },
        validationErrorEvent: GTagEvents.reg_pep_hio_consent_error,
        'data-cy': 'registration-consents-info-pep',
      },
    ],
    [],
  );

  const initialValues: Consents = {
    tcConsent,
    marketingConsent,
    pepHioConsent,
    privacyAndCookiePolicyConsent,
  };

  const successCallback = useCallback((data: BffRegisterResponse) => {
    CookiesManagerUtils.removeBtagCookie();
    const isTwoFactorAuthentication = data?.isTwoFactorAuthentication;
    const userId = data?.userId;
    const token = data?.token;

    if (!isTwoFactorAuthentication && token && userId) {
      triggerGTag(GTagEvents.registration_success, { userId });
      triggerNetReferer('Sign Up', btag, userId);
      dispatch(loginSuccess(token));
      updateFields({ token, userId });
      return;
    }
    if (isTwoFactorAuthentication && userId) {
      triggerGTag(GTagEvents.reg_2fa_started, { userId });
      updateFields({ isTwoFactorAuthentication, userId });
      return;
    }
  }, []);

  const errorCallback = useCallback((error: BffApiError) => {
    triggerGTag(GTagEvents.reg_failed, { error: `bff-error-${error.code}` });
    // 1. for error ACCOUNT_BLOCKED we want to do a special error handling, also we want to clear the registration state in this case
    if (error.code === BffErrorCode.GIG_ACCOUNT_BLOCKED) {
      resetRegistrationStore();
      triggerGTag(GTagEvents.reg_failed_acc_blocked);
      setShowError(error.code);
    } else if (error.code === BffErrorCode.REGISTER_2FA_LOGIN_ERROR) {
      resetRegistrationStore();
      triggerGTag(GTagEvents.reg_success_login_failed);
      setShowError(error.code);
      return;
    } else if (error.code === FrontendErrorCode.Forbidden) {
      setErrorMessage(
        'Se pare că încerci să-ți creezi un cont cu un IP din afara României. Te rugăm să încerci din nou sau să contactezi serviciul de suport clienți',
      );
      return;
    }
    setErrorMessage(
      'Ceva nu a mers bine cu înregistrarea ta. Vă rugăm să contactați asistența. [' +
        error.code +
        ']',
    );
  }, []);

  const handleOnSubmit = useCallback(() => {
    const { fullName, email, password, address, postalCode, city, phone, cnp } = rest;
    triggerGTag(GTagEvents.reg_step3_submit_cta_click);

    const payload = {
      fullName,
      email,
      password,
      address,
      city,
      cnp,
      postalCode,
      phone: phone.number,
      phonePrefix: phone.prefix,
      emailConsented: marketingConsent,
      smsConsented: marketingConsent,
      btag,
    };

    setShowError(undefined);
    setErrorMessage('');
    registerRequest({
      apiMethod: BffApiService.register,
      payload,
      successCallback,
      errorCallback,
    });
  }, [rest, successCallback, errorCallback]);

  return (
    <div className="w-full h-full pt-7">
      <h4 className="text-brand-gold text-2xl text-center m-0 font-semibold">Aproape acolo!</h4>
      <h5 className="text-gray-700 text-center m-0 text-sm sm:text-base mb-12">
        Acceptă termenii noștri și începe-ți călătoria! 🥳
      </h5>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        className="flex flex-col justify-between gap-5"
      >
        {({ isValid }) => (
          <>
            {data.map(
              (
                { name, label, labelClassName, onChange, validationErrorEvent, ...consent },
                index,
                arr,
              ) => (
                <React.Fragment key={name}>
                  <SwitchField
                    name={name}
                    label={label}
                    labelClassName={labelClassName}
                    onChange={onChange}
                    onValidationError={error => {
                      if (validationErrorEvent) {
                        triggerGTag(validationErrorEvent, { error });
                      }
                    }}
                    {...consent}
                  />
                  {index < arr.length - 1 && <Divider />}
                </React.Fragment>
              ),
            )}
            {errorMessage && (
              <p
                onClick={() => {
                  triggerGTag(GTagEvents.reg_error_step3_help_click);
                  openZendesk();
                }}
                className="cursor-pointer m-0 w-full bg-red-300 rounded-xl px-4 py-3 text-red-700 text-sm"
              >
                {errorMessage}
              </p>
            )}
            <div className="w-2/3 mx-auto grid grid-cols-2 gap-x-[16px] sm:gap-x-[24px] mt-4 sm:mt-6 mb-3">
              <Button
                disabled={loading}
                type="button"
                color="ghostBlack"
                onClick={() => {
                  triggerGTag(GTagEvents.reg_step3_back_cta_click);
                  stepBack();
                }}
              >
                Spate
              </Button>
              <Button
                data-cy="registration-consents-info-submit"
                disabled={!isValid || loading}
                type="submit"
              >
                {loading ? 'Un moment' : 'Finalizați'}
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default ConsentsForm;
